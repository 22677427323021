import * as React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Layout from "../../components/Layout";
import banner_0 from "../../images/news/mission-to-titan/banner_0.webp";
import banner_1 from "../../images/news/mission-to-titan/banner_1.webp";
import banner_2 from "../../images/news/mission-to-titan/banner_2.webp";
import img_1_0 from "../../images/news/mission-to-titan/img_1_0.webp";
import img_1_1 from "../../images/news/mission-to-titan/img_1_1.webp";
import img_1_2 from "../../images/news/mission-to-titan/img_1_2.webp";
import img_2_0 from "../../images/news/mission-to-titan/img_2_0.webp";
import img_2_1 from "../../images/news/mission-to-titan/img_2_1.webp";
import img_2_2 from "../../images/news/mission-to-titan/img_2_2.webp";

const lorem =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ";

export default function PostPage() {
  return (
    <Layout title="Astraeus' Mission to Titan | Conex Research">
      <section className="w-screen h-fit mt-6 mb-20">
        <div className="px-8 lg:px-20">
          <h5 className="mb-10 sm:hidden">
            <a href="/media">{"< "}Back to News</a>
          </h5>
          <h3 className="text-xl ws:text-3xl font-light text-slate-700 mb-3">
            31 Aug 2022
          </h3>
          <h1 className="text-5xl sm:text-7xl ws:text-8xl font-semibold mb-3">
            The Astraeus Mission to Titan
          </h1>
          <h2 className="text-xl ws:text-3xl sm:text-2xl mb-3">
            A new mission by Conex Research called ‘Astraeus’ is being designed
            to explore Saturn's moon, Titan.
          </h2>

          <div className="grid grid-rows-1 grid-cols-2">
            <h3 className="font-light text-slate-700 justify-self-start ws:text-2xl">
              By{" "}
              <a
                className="text-conex-blue"
                href="https://twitter.com/alicenewspace"
                target="_blank"
              >
                Alisa Zaripova
              </a>
            </h3>
            <div className="w-full flex flex-row justify-end gap-3">
              <a
                href={
                  "https://twitter.com/intent/tweet?url=https%3A%2F%2Fconexresearch.com%2Fnews%2Fmission-to-titan&via=ConexResearch&text=The%20Astraeus%20Mission%20to%20Titan"
                }
                title="Share on Twitter"
                target="_blank"
                className="flex items-center p-1"
              >
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="w-5 h-5 text-conex-blue"
                >
                  <path d="M23.954 4.569a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="w-full h-fit mt-20">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval={4000}
            transitionTime={1000}
            showThumbs={false}
            showStatus={false}
          >
            <div>
              <img src={banner_0} />
            </div>
            <div>
              <img src={banner_1} />
            </div>
            <div>
              <img src={banner_2} />
            </div>
          </Carousel>
        </div>
      </section>

      <section>
        <p className="font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64">
          <strong className="font-semibold">Background</strong>
          <br />
          <br />
          A new mission by Conex Research called ‘Astraeus’ is being designed to
          explore Saturn's moon, Titan. Titan is the only moon with a dense
          atmosphere in the Solar System for which the stable existence of
          liquid on the surface has been proven, and so it provides both a large
          number of science questions, and an engineering challenge.
          <br />
          <br />
          Another celestial body with these parameters is our home, Planet
          Earth. But it could be asked, ‘does anything else unite the Earth and
          the cold moon of the gas giant Saturn? And is it as cold as it seems
          at first glance?’
        </p>

        <p className="font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64">
          <strong className="font-semibold">History</strong>
          <br />
          <br />
          First, let's take a moment to revisit the path of exploration of
          Titan. We can put forward theories about Titan's geology, internal
          structure and physical chemical features thanks to data from previous
          missions. Throughout history, there have been only three missions that
          allowed scientists to seriously assess the potential of the ice giant:
          Pioneer 11, Voyager 1, and Cassini-Huygens.
          <br />
          <br />
          In 1979, Pioneer 11 provided us with the first image of the moon,
          giving us a glimpse of Titan's dense atmosphere and confirming that
          Titan has extremely low temperatures. Later in 1980 and 1981, the
          Voyager 1 and 2 spacecraft saw a blue haze that appeared to be a
          separate layer of Titan's upper atmosphere. Furthermore, Voyager 1
          showed that Titan had traces of acetylene, ethane and propane, as well
          as other organic molecules, and that its atmosphere consisted mostly
          of nitrogen. Using its instruments, it transmitted surface temperature
          data and also measured the radius of the moon showing that Titan is
          the second largest moon in the Solar System.
          <br />
          <br />
          The Cassini-Huygens mission, which orbited Saturn in 2004, provided us
          with the most complete dataset we have to date. Over 13 years, the
          Cassini spacecraft made 127 close passes over Titan, using a suite of
          instruments, including radar and infrared, to penetrate through the
          haze of Titan's atmosphere and acquire numerous data and images of its
          surface. The Huygens probe was the first spacecraft to descend to the
          surface of a moon in the outer Solar System on the 14th of January,
          2005, providing scientists with spectacular images of the distant
          world. In the future, NASA plans to send another mission to Titan
          called Dragonfly. This mission would send a rotary wing aircraft to
          Titan in 2027 to fly to a large number of promising sites to collect
          scientific data, including prebiotic chemical processes.
        </p>

        <p className="font-light text-lg ws:text-3xl leading-relaxed px-8 lg:px-64">
          <strong className="font-semibold">Titan Environment</strong>
          <br />
          <br />
          Upon inspection of our data, it turns out that this world is not as
          alien as it might have seemed at first glance. Titan has clouds, rains
          and reservoirs filled with liquid hydrocarbons, and a subsurface ocean
          of salt water almost certainly exists. Numerous images have allowed
          scientists to identify individual structures that make up the
          topography of Titan: mountain systems, dunes, impact structures
          (shapes resembling wells from a meteorite impact), lakes and seas.
          There are theories about the presence of cryovolcanic structures, some
          images resemble folded systems, like those we can observe on Earth,
          dotted with numerous faults, which could be evidence of a solid crust
          and geological activity on Titan. This is also supported by the
          hypothesis of a hydrocarbon cycle, i.e. the evaporation of Titan into
          the atmosphere followed by precipitation, but there are models in
          which the replenishment of methane in the atmosphere must come from
          some other source, such as from the interior.
          <br />
          <br />
          In addition to existing hypotheses, we put forward the assumption that
          an impressive part of Titan's geomorphology is composed of glaciers.
          For a glacier to form, it is necessary to have an underlying surface,
          constant negative temperature and atmospheric precipitation (amount of
          900 mm/year). The development of instruments capable of obtaining more
          data about surface of Titan would provide us with perhaps the most
          comprehensive data on geological activity on its surface, and thus on
          evolution in general. Glaciers form complex and specific landforms
          over many years, and such structures can be mistaken for mountain
          formations or even river systems due to insufficient image resolution.
          However, when studied in more detail, glacial valleys cannot be
          confused with anything else.
        </p>

        <div className="flex flex-col md:flex-row gap-10 md:gap-10 lg:gap-20 p-10 md:p-8 lg:p-16 basis-1/3">
          <div>
            <img src={img_1_0} className="rounded-xl drop-shadow-sm" />
          </div>
          <div>
            <img src={img_1_1} className=" rounded-xl drop-shadow-sm" />
          </div>
          <div>
            <img src={img_1_2} className="rounded-xl drop-shadow-sm" />
          </div>
        </div>

        <p className="font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64">
          But let's talk about where exactly we want to land on Titan and why.
          Before discussing the most attractive landing sites for the Astraeus
          mission, we suggest recalling the landing site of the previous
          Cassini-Huygens mission and what it was notable for. Huygens made a
          graceful parachute landing in Titan's equatorial region, 192.32
          degrees west longitude and 10.25 degrees south latitude, about 4 miles
          (7 kilometers) from its target point, to be exact. The probe
          transmitted invaluable data to Cassini, which it then relayed to
          Earth. The images of the Huygens landing site resembled a parched and
          frozen river valley, composed of various sizes of rounded rock/ice
          fragments. For the Dragonfly mission, planned for arrival at Titan in
          the 2030s, the equatorial "Shangri-La" dune fields was chosen as the
          starting point. The spacecraft will then travel to the Selk impact
          structure, which is just north of where the Huygens probe landed, to
          look for traces of past water and organic molecules along the way,
          collecting samples.
        </p>

        <p className="font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64">
          <strong className="font-semibold">Landing Sites</strong>
          <br />
          <br />
          The Conex team has chosen four strategic locations for the Astraeus
          mission: three locations suitable for landing and detailed in-situ
          exploration, these being the lakes near Titan's north pole and their
          surroundings. One location which will hopefully be explored during the
          flyby from about 40 km altitude is a structure called Ganesa Macula,
          to which we will return a little later.
          <br />
          <br />
          Why the lakes? To date, scientists disagree on the origin of Titan's
          hydrocarbon lakes in its northern polar region. There are theories of
          impact, volcanic and karst origin. But to refute or prove this or that
          theory we need more data and missions. One thing becomes clear -
          Titan's crust probably consists not only of solid ice, as many people
          used to think, but of a more diverse structure and chemical
          composition. Also, in theory, the formation of lakes of this type may
          be preceded by hydrolaccoliths, similar to those developed in the
          northern latitudes on Earth. They are formed in the permafrost zone
          and consist of frozen ground layered with ice. This process is also
          associated with surface drying of thermokarst lakes.
          <br />
          <br />
          Below are the main characteristics of each potential location:
        </p>

        <p className="font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64">
          <strong className="font-semibold">Lake Kraken Mare</strong>
          <br />
          <br />
          This lake is the largest lake known to date on Titan (500,000 km
          <sup>2</sup>), and therefore is the most win-win location and is
          located in a depression and reminiscent of terrestrial crater lakes.
          It is thought to be filled with 70% methane, 16% nitrogen and 14%
          ethane.
        </p>

        <p className="font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64">
          <strong className="font-semibold">Lake Ligeia Mare</strong>
          <br />
          <br />
          It is the second largest body of liquid on the surface of Titan, after
          Kraken Mare. Measuring roughly 420 km (260 mi) by 350 km (217 mi)
          across, it has a surface area of about 126,000 km<sup>2</sup>, and a
          shoreline over 2000 km (1240 mi) in length. It is mostly composed of
          liquid methane, with unknown but lesser components of dissolved
          nitrogen and ethane. The lake may be hydrologically connected to the
          larger Kraken Mare and may be studied in association with the Kraken
          Mare. The average depth is on the order of 50 m, while the maximum
          depth is probably {">"} 200 m. The total volume is likely to be {">"}{" "}
          7000 km
          <sup>3</sup>.
        </p>

        <p className="font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64">
          <strong className="font-semibold">Lake Mackay Lacus</strong>
          <br />
          <br />
          Located east of the two previous lakes, and is not particularly large,
          Lake Mackay Lacus’s location may allow us in one mission to explore
          several distant points, namely to fly over the structure Ganesa
          Macula, according to some scientists a structure representing a system
          of cryovolcanoes. If so, we will be able to assess the physical and
          chemical composition of Titan's interior, as well as get closer to
          answering the question of the existence of biological molecules
          capable of evolving into a living organism.
        </p>

        <p className="font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64">
          <strong className="font-semibold">Ganesa Macula</strong>
          <br />
          Ganesa Macula has a diameter of 180 km, and the coordinates of the
          center are 49°42′N. 87°18′ W. 49.7°N. 87.3° W. In the center is a
          20-kilometer bright spot, visible in Cassini’s Radar measurements, of
          unknown origin. Within Ganesa, the East, West and Central calderas are
          distinguished. The West Caldera and the irregularly shaped crater
          stream can be seen about 1070 km from the western edge of Ganesa. The
          caldera is about 18 km in diameter, the flow is at least 84 km long,
          and the minimum area is 1020 km<sup>2</sup> . The largest field
          complex of the flow (Winia Fluctus, at least 23,700 km<sup>2</sup>)
          is located about 1,340 km east of the eastern edge of Ganesa. The flow
          extends from 50° west longitude, 52° north latitude to 44° west
          longitude, 47° north latitude.
          <br />
          <br />
          Ganesa Macula is a special structure on Titan. According to the SAR Ta
          data of the Cassini mission, this structure has the greatest potential
          to be a region of cryovolcanoes. Radar data and modeling suggest that
          Ganesa Macula has a similar morphology to shield volcanoes common on
          Earth, Venus, Mars and the Moon. It is worth noting that the
          structures described in the scientific papers on Titan strongly
          resemble glacial valleys, which, however, does not exclude the
          existence of cryovolcanic structures that have undergone glacial
          erosion over time.
        </p>

        <div className="flex flex-col md:flex-row gap-10 md:gap-10 lg:gap-20 p-10 md:p-8 lg:p-16 basis-1/3">
          <div>
            <img src={img_2_0} className="rounded-xl drop-shadow-sm" />
          </div>
          <div>
            <img src={img_2_1} className=" rounded-xl drop-shadow-sm" />
          </div>
          <div>
            <img src={img_2_2} className="rounded-xl drop-shadow-sm" />
          </div>
        </div>

        <p className="font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64">
          <strong className="font-semibold">Vehicles {"&"} Instruments</strong>
          <br />
          <br />
          The Conex team is developing unique instruments to obtain new data
          that shed light on the origin of the lakes, the composition of Titan's
          crust, the atmospheric features of the moon, and to clarify the
          presence of cryovolcanoes and the possible existence of biological
          molecules that can organize complex life chains. Our team is now
          working on a Main Orbital Spacecraft (MOS) which comprises the major
          bus element that will hold all other spacecraft. The MOS will host a
          Mayfly-Manta system, the Manta being a marine vehicle, which is a
          deployable probe from the Mayfly airborne vehicle. Mayfly will perform
          an aerial observation of potential landing sites on Titan's lakes,
          where landing on a suitable surface requires the system to lower the
          Manta into the lake. The system will be equipped with a descent mass
          spectrometer as well as a device for fluid sampling and analysis in
          the study of circumpolar lakes (‘vacuum cleaner’ sample collector). In
          addition, the probe will be equipped with an accelerometer,
          thermometer, sonar, dielectric sensor, pressure sensor and sound speed
          sensor. Moreover, we plan to use a CubeSats 2U, called the Mites,
          weighing not more than 2 kg, that will be deployed from 1400 km in
          altitude into a low decay-rate orbit and equipped with ion-neutral
          mass spectrometer to measure heavy anions. These Cubesats will also
          carry an accelerometer, thermometer and barometer. We also plan to
          survey Titan's surface during the flyby with synthetic aperture radar
          (SAR), and study the physical and chemical properties of Titan's crust
          with gamma-ray and neutron spectrometer.
          <br />
          <br />
          The Conex Research plans to present new mission to the Titan at IAC
          2022 in Paris. Join us as we begin our journey to one of the Solar
          System's most mysterious moons.
        </p>
      </section>
    </Layout>
  );
}
